// $theme-color: #4636ff;
$troy-blue: #244ca1;
$cyan-process: #2fbff1;
$green-blue-crayolav: #2c88c9;
$tufts-blue: #4482c3;
$dark-indigo: #1b154c;
$midnight-express: #140035;
$theme-color: #1b154c;
$body-text-color: #686777;

section.services,
section#our_vision {
  position: relative;
  overflow: hidden;
}
.background-abstract {
  position: absolute;
  z-index: -1;

  &.z-1 {
    z-index: 0;
  }

  &.abs-1 {
    top: -5%;
    left: -220px;
    max-width: 550px;
  }

  &.abs-2 {
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
    max-width: 250px;
  }

  &.abs-3 {
    top: 15%;
    right: -150px;
    max-width: 450px;
  }
  &.abs-4 {
    bottom: 0%;
    left: -250px;
    width: 650px;
  }
  &.abs-5 {
    top: 10%;
    right: 80px;
    max-width: 250px;
    opacity: 0.5;
  }

  &.abs-6 {
    bottom: -1%;
    left: -220px;
    max-width: 550px;
  }

  &.abs-7 {
    top: 10%;
    left: 30px;
    max-width: 200px;
    opacity: 0.5;
  }

  &.abs-8 {
    top: 25%;
    right: 5%;
    width: 10vw;
    max-width: 350px;
    opacity: 0.5;
  }

  &.abs-9 {
    bottom: 10%;
    left: 0%;
    opacity: 1;
    transform: rotate(90deg);
  }

  &.abs-10 {
    bottom: -5%;
    right: 15%;
    width: 10vw;
    max-width: 350px;
    opacity: 1;
  }
}

.services-heading {
  font-weight: 600 !important;
}

.services-list-grid {
  .services-list-item {
    background-color: #fff;
    display: flex;
    min-height: 330px;

    width: 100%;
    justify-content: start;
    align-items: stretch;
    margin-bottom: 1.5rem;
    border-radius: 1rem;
    box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      box-shadow 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    &.vertical-card {
      flex-direction: column;
      justify-content: stretch;
      min-height: 600px;

      .service-icon {
        width: 100%;
        min-height: 150px;
      }

      .service-content {
        width: 100%;
        height: 75%;
      }
    }

    &:hover {
      box-shadow: 0 18px 40px rgb(51 51 51 / 10%);
      z-index: 9;
      transform: scale(1.03);
      transition: all 0.3s ease-in-out;
    }

    .service-icon {
      flex-grow: 0.3;
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 5rem;
      background: linear-gradient(-130deg, #dd24ff 0%, #841ff9 100%);
      background: linear-gradient(
        -130deg,
        $tufts-blue 0%,
        $midnight-express 100%
      );
      color: #ffffff;
    }

    .service-content {
      display: flex;
      flex-grow: 1;
      width: 70%;
      padding: 1.5rem;
      flex-direction: column;

      .about__list {
        margin: 0;

        ul {
          margin-left: 30px;
          li {
            position: relative;
            font-size: 18px;
            line-height: 1.5;
            // font-weight: 600;

            span {
              position: absolute;
              left: -30px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }

    .service-title h3 {
      margin-bottom: 1.5rem;
    }
  }
}

#technologies_section {
  position: relative;
  overflow: hidden;

  .technology-card {
    .card-thumb img {
      max-width: 100%;
      cursor: pointer;
    }

    &:hover {
      .card-thumb img {
        transform: scale(1.03);
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

#our_vision {
  background-color: $dark-indigo;
  // background: linear-gradient(to Right, #1e09c3, #3b22f7);
  color: #ffffff;

  .section__title * {
    color: inherit;
  }

  .our-vision-row .col {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
  }

  .vision-card {
    position: relative;
    width: 100%;
    // aspect-ratio: 4/5;
    display: flex;
    margin-bottom: 2rem;

    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    row-gap: 1rem;
    background: #1e02f0;
    background-color: $dark-indigo;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    padding: 2.5rem 1.5rem;
    color: #ffffff;

    .vision-icon {
      font-size: 4rem;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      transition: all 0.3s ease-in-out;
    }

    h3,
    p {
      color: inherit !important;
      text-align: center;
      margin-bottom: 0;
    }

    &::before {
      transition: all 0.3s ease-in-out;
      z-index: -1;
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      // background: #ffffff;
      // background: linear-gradient(315deg, #9517e3, #1b154c, #4cdaf6);
      background: linear-gradient(
        -130deg,
        #dd24ff 0%,
        #1b154c 40%,
        #1b154c 60%,
        #841ff9 100%
      );
      // background: linear-gradient(
      //   -315deg,
      //   $cyan-process 0%,
      //   #1b154c 40%,
      //   #1b154c 60%,
      //   #e71ff9 100%
      // );
    }

    &::after {
      z-index: -2;
      content: "";
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      // background: #ffffff;
      // background: linear-gradient(315deg, #9517e3, #1b154c, #4cdaf6);

      // background: linear-gradient(
      //   -315deg,
      //   $cyan-process 0%,
      //   #1b154c 40%,
      //   #1b154c 60%,
      //   #e71ff9 100%
      // );

      background: linear-gradient(
        -130deg,
        #dd24ff 0%,
        #1b154c 40%,
        #1b154c 60%,
        #841ff9 100%
      );
      filter: blur(0px);
    }

    &:hover {
      // transform: translateY(-30px);

      .vision-icon {
        transform: translateY(-10px);
        color: #e71ff9 !important;
      }

      &::before {
        background: linear-gradient(
          -130deg,
          #dd24ff 0%,
          #1b154c 35%,
          #1b154c 75%,
          #841ff9 100%
        );
      }

      &::after {
        filter: blur(30px);
      }
    }
  }

  // .vision-card:hover .vision-card::before {
  //   background: linear-gradient(315deg, #dd24ff 0%, #1b154c, #841ff9 100%);
  // }
}

section#core_values {
  background-size: 100%;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  .core_value_value {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 18px 40px rgb(51 51 51 / 30%);
    border-radius: 1rem;
    background: linear-gradient(-235deg, $troy-blue 0%, $dark-indigo 100%);
    color: #ffffff;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: scale(1.03) translateY(-10px);
    }

    .core_value_icon {
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 3.5rem;
      margin: 1.5rem 0rem;
      padding: 0rem 1rem;
    }

    .core_value_content {
      width: 75%;
      padding: 1.5rem;
      font-size: 1.25rem;
      line-height: 1.2;
    }
  }
}

section#team_xenotive {
  background-color: $dark-indigo;
  color: #fff;
  background-color: #140035;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  .section__title {
    margin-bottom: 70px;
  }
  .section__title * {
    color: #fff;
  }

  .team-card {
    cursor: pointer;
    border-radius: 1rem;
    background-color: #ffffff;
    min-height: 400px;
    margin-bottom: 1.5rem;

    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 1rem;
    transition: all 0.3s ease-in-out;

    .card-image {
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: row;
      padding: 1.5rem;
      img {
        width: 230px;
        max-width: 80%;
        border-radius: 50%;
        box-shadow: 0 2px 12px 0 rgb(0 0 0 / 30%);
      }
    }

    .card-cta a {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.2rem;
      font-weight: bold;
      column-gap: 0.5rem;
      line-height: 0.75;
    }

    &:hover {
      transform: scale(1.03) translateY(-30px);
    }
  }
}
